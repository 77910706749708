import React from 'react'
import '../assets/css/Footer.styl'
function Footer() {
        return (
            <div className="footer fc-hui7 flex-column flex-items-center flex-justify-center">
               <div className="fs-16 mt-16">© 武汉晴空飞翼网络科技有限公司 版权所有</div>
               <div className="fs-16 mt-16"><a className="mt-17" href="https://beian.miit.gov.cn/#/Integrated/recordQuery" target="_blank">鄂ICP备2022008343号-1</a></div>
               <div className="fs-16 mt-16">联系电话：15210172760</div>
               <div className="fs-16 mt-16">联系地址：武汉临空港经济技术开发区五环大道666号</div>
            </div>
        );
    }
export default Footer;