import React from 'react'
import { HashRouter as Router, Route } from 'react-router-dom'
import Home from './view/Home'
import About from './view/About'
import Product from './view/Product'
import Information from './view/Information'
import Header from './components/Header'
import Footer from './components/Footer'
function App() {
  return (
    <div className="App">
      <Router>
        <Header />
        <Route path="/" exact component={Product}/>
        <Route path="/about" component={About}/>
        <Route path="/product" component={Product}/>
          <Route path="/information" component={Information}/>
        <Footer />
      </Router>
    </div>
  )
}
export default App
