import { useMemo, useRef } from 'react';

function useMemoizedFn(fn) {
    if (process.env.NODE_ENV === 'development') {
        if (typeof fn !== 'function') {
            console.error(`useMemoizedFn expected parameter is a function, got ${typeof fn}`);
        }
    }

    const fnRef = useRef(fn);

    // why not write `fnRef.current = fn`?
    // https://github.com/alibaba/hooks/issues/728
    fnRef.current = useMemo(() => fn, [fn]);

    const memoizedFn = useRef();
    if (!memoizedFn.current) {
        memoizedFn.current = function (...args) {
            return fnRef.current.apply(this, args);
        };
    }

    return memoizedFn.current;
}

export default useMemoizedFn;
