import React from 'react'
import '../assets/css/Product.styl'
function Product() {
        return (
            <div className="product">

                

                <div className="flex-justify-center box">
                    <div className="flex-column content  flex-justify-center">
                        <img src={require("../assets/images/logo8.png")} alt="" width="118" height="118"/>
                        <div className="fs-40 mt-24">
                        疾速充电助手
                        </div>
                        <div className="fs-20 mt-24 bold-200">
                        你的智能出行助手，让你的出行更加绿色、便捷、舒适。
                        </div>
                        <a href="https://bj-zlb.oss-cn-beijing.aliyuncs.com/static_html/jscdzs_q/app.rpk" target="_blank">
                            <div className="download-btn">
                                下载应用
                            </div>
                        </a>
                        <a href="jscdzs_q/index.html" target="_blank">
                            <div className="detail-btn">
                                查看更多截图
                            </div>
                        </a>
                    </div>
                    <div>
                        <img src={require("../assets/images/phone_8.png")} alt="" width="324" height="514"/>
                    </div>
                </div>

                <div className="flex-justify-center box">
                    <div>
                        <img src={require("../assets/images/phone_7.png")} alt="" width="324" height="514"/>
                    </div>
                    <div className="flex-column content-right  flex-justify-center">
                        <img src={require("../assets/images/logo7.png")} alt="" width="118" height="118"/>
                        <div className="fs-40 mt-24">
                        清风助眠管家（快应用）
                        </div>
                        <div className="fs-20 mt-24 bold-200 ">
                        清风助眠管家自带超多舒缓音乐帮您安心入眠，还有放松小工具，帮你弄缓解压力放松身心。
                        </div>
                        
                        <a href="qfzmgj_q/index.html">
                            <div className=" detail-btn">
                                查看更多截图
                            </div>
                        </a>
                        <a href="https://bj-zlb.oss-cn-beijing.aliyuncs.com/static_html/qfzmgj_q/app.rpk">
                            <div className="download-btn">
                               下载应用
                            </div>
                        </a>
                    </div>
               </div>

                <div className="flex-justify-center box">
                    <div className="flex-column content  flex-justify-center">
                        <img src={require("../assets/images/logo6.png")} alt="" width="118" height="118"/>
                        <div className="fs-40 mt-24">
                            房贷智多星（快应用）
                        </div>
                        <div className="fs-20 mt-24 bold-200 fc-hui8">
                        房贷智多星内置多种房贷计算方式、贷款方式，帮助您寻找到更适合您的贷款方式。
                        </div>
                        <a href="fdzdx_q/index.html">
                            <div className="detail-btn">
                                查看更多截图
                            </div>
                        </a>
                        <a href="https://bj-zlb.oss-cn-beijing.aliyuncs.com/static_html/fdzdx_q/app.rpk" target="_blank">
                            <div className="download-btn">
                                下载应用
                            </div>
                        </a>
                    </div>
                    <div>
                        <img src={require("../assets/images/phone_6.png")} alt="" width="324" height="514"/>
                    </div>
               </div>

                <div className="flex-justify-center box">
                    <div>
                        <img src={require("../assets/images/phone_5.png")} alt="" width="324" height="514"/>
                    </div>
                    <div className="flex-column content-right  flex-justify-center">
                        <img src={require("../assets/images/logo5.png")} alt="" width="118" height="118"/>
                        <div className="fs-40 mt-24">
                        享看大字版（快应用）
                        </div>
                        <div className="fs-20 mt-24 bold-200 ">
                        享看大字版是专门为中老年设计的实用软件， 可随时修改字体大小，方便中老年朋友查看和操作，好用更省心。
                        </div>
                        
                        <a href="xkdzb_q/index.html">
                            <div className=" detail-btn">
                                查看更多截图
                            </div>
                        </a>
                        <a href="https://bj-zlb.oss-cn-beijing.aliyuncs.com/static_html/xkdzb_q/app.rpk">
                            <div className="download-btn">
                               下载应用
                            </div>
                        </a>
                    </div>
               </div>
               
                {/* 旋风 */}
                <div className="flex-justify-center box">
                    <div className="flex-column content  flex-justify-center">
                        <img src={require("../assets/images/logo1.png")} alt="" width="118" height="118"/>
                        <div className="fs-40 mt-24">
                            享看大字版（app）
                        </div>
                        <div className="fs-20 mt-24 bold-200 fc-hui8">
                            查看资讯、放大镜、万年历一应俱全，还都是大字版，看起来更轻松更清晰
                        </div>
                        <a href="xkdzb/index.html">
                            <div className="detail-btn">
                                查看详情
                            </div>
                        </a>
                        <a href="https://bj-zlb.oss-cn-beijing.aliyuncs.com/static_html/xkdzb/app.apk" target="_blank">
                            <div className="download-btn">
                                下载应用
                            </div>
                        </a>
                    </div>
                    <div>
                        <img src={require("../assets/images/phone_1.png")} alt="" width="324" height="514"/>
                    </div>
               </div>

            

               <div className="flex-justify-center box">
                    <div>
                        <img src={require("../assets/images/phone_2.png")} alt="" width="324" height="514"/>
                    </div>
                    <div className="flex-column content-right  flex-justify-center">
                        <img src={require("../assets/images/logo2.png")} alt="" width="118" height="118"/>
                        <div className="fs-40 mt-24">
                        风驰清理大师
                        </div>
                        <div className="fs-20 mt-24 bold-200 ">
                        专门的手机清理软件，不仅可以清理手机垃圾，还可解决手机安全问题
                        </div>
                        
                        <a href="fcqlds/index.html">
                            <div className=" detail-btn">
                                查看详情
                            </div>
                        </a>
                        <a href="https://bj-zlb.oss-cn-beijing.aliyuncs.com/static_html/fcqlds/app.apk">
                            <div className="download-btn">
                               下载应用
                            </div>
                        </a>
                    </div>
               </div>


               <div className="flex-justify-center box">
                    <div className="flex-column content  flex-justify-center">
                        <img src={require("../assets/images/logo3.png")} alt="" width="118" height="118"/>
                        <div className="fs-40 mt-24">
                        福莱文件管理
                        </div>
                        <div className="fs-20 mt-24 bold-200 fc-hui8">
                        福莱文件管理是一款专为安卓手机设计的智能文件管理软件。适用于男女老少，仅需傻瓜式操作即可系统管理手机内文件并清理智能管理手机内存，更加方便您的手机管理，让运行速度飞起来
                        </div>
                        <a href="flwjgl/index.html">
                            <div className=" detail-btn">
                                查看详情
                            </div>
                        </a>
                        <a href="https://bj-zlb.oss-cn-beijing.aliyuncs.com/static_html/flwjgl/app.apk" target="_blank">
                            <div className="download-btn">
                                下载应用
                            </div>
                        </a>
                    </div>
                    <div>
                        <img src={require("../assets/images/phone_3.png")} alt="" width="324" height="514"/>
                    </div>
               </div>

               <div className="flex-justify-center box">
                    <div>
                        <img src={require("../assets/images/phone_4.png")} alt="" width="324" height="514"/>
                    </div>
                    <div className="flex-column content-right  flex-justify-center">
                        <img src={require("../assets/images/logo4.png")} alt="" width="118" height="118"/>
                        <div className="fs-40 mt-24">
                        乐知空气
                        </div>
                        <div className="fs-20 mt-24 bold-200 ">
                        使用乐知空气，规划您的日常活动比过去更加简单：只需选好要追踪的天气参数和喜欢的布局，就能查看您的个性化天气预报！ 
                        </div>
                        
                        <a href="lzkq/index.html">
                            <div className=" detail-btn">
                                查看详情
                            </div>
                        </a>
                        <a href="https://bj-zlb.oss-cn-beijing.aliyuncs.com/static_html/lzkq/app.apk">
                            <div className="download-btn">
                               下载应用
                            </div>
                        </a>
                    </div>
               </div>



            
            </div>
        );
    }
export default Product;
