import React from 'react'
import '../assets/css/About.styl'
function About() {
        return (
            <div className="about flex-justify-center flex-column">
               <div className="banner flex-items-center flex-justify-center bold fc-black">
              
               </div>
               <div className="flex-column flex-items-center">
                    <div className="about-title fs-36 mt-36 bold">
                        <div className="tit">
                            关于我们
                        </div>
                    </div>
                    <div className="about-content fs-24 text-center fc-hui8">
                        <div>
                        武汉晴空飞翼网络科技有限公司
                        </div>
                        <div className="mt-30">
                        联系电话：15210172760（周一至周日，10:00-18:00）
                        </div>
                        <div className="mt-30">
                        联系地址：武汉临空港经济技术开发区五环大道666号
                        </div>
                    </div>
               </div>
            </div>
        );
    }
export default About;