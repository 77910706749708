import React, {useEffect, useImperativeHandle, useRef, useState} from 'react'
import ReactDOM from "react-dom";
import '../assets/css/Information.styl'
import useMemoizedFn from '../hooks/useMemoizedFn'

const Item = React.memo(({title,onBtnClick})=>{
    return (
        <div className={'item'}>
            <div className="title">{title}</div>
            <div className="btn">
                <button onClick={onBtnClick}>支付15元阅读</button>
            </div>
        </div>
    )
})

const Modal = React.memo(React.forwardRef((props,ref)=>{
    const [visible,setVisible] = useState(false)
    useImperativeHandle(ref,()=>{
        return {
            setVisible
        }
    })
    const qrRef = useRef()
    const ModalContent = (
        <div className="modal_container" onClick={e=>{
            if(e.target && e.target.contains(qrRef.current) && e.target !== qrRef.current){
                setVisible(false)
            }
        }}>
            <img className="qr" ref={qrRef} src={require('../assets/images/img_qr.png')} alt=""/>
        </div>
    )
    if(visible){
        return ReactDOM.createPortal(ModalContent,document.body)
    }
    return null
}))


function Information() {
    const [list] = useState([
        {
            title:'2021中国新零售行业研究报告'
        },
        {
            title: '流量变现消费洞察报告'
        },
        {
            title: 'ToB角度解读2021市场发展'
        },
        {
            title: '存量经济时代，引爆私域流量'
        },
        {
            title: '2021广告市场调查报告'
        },
        {
            title: '2021中国移动互联网报告十大趋势'
        }
    ])
    useEffect(()=>{
        document.body.classList.add('info_bg')
        document.querySelector('.footer').style.backgroundColor = 'transparent'
        return ()=>{
            document.body.classList.remove('info_bg')
            document.querySelector('.footer').style.backgroundColor = ''
        }
    },[])
    const ModalRef = useRef()

    const onBtnClick = useMemoizedFn(()=>{
        ModalRef.current.setVisible(true)
    })
    return (
        <div className="information">
            <div className="content">
                {
                    list.map((item,index)=>{
                        return <Item title={item.title} onBtnClick={onBtnClick} key={index}/>
                    })
                }
            </div>
            <Modal ref={ModalRef}/>
        </div>
    );
}
export default Information;
